
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden; /* Prevents horizontal scrollbar */
  background-color: #051622;
  color: white;
  font-family: 'Courier New', Courier, monospace, sans-serif;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #fff;
}

hr {
  opacity: 0;
  margin-top: 160px;
}

h1,
h2,
h3,
h4 {
  text-transform: uppercase;
  font-family: 'Anek Telugu', sans-serif;
}

span {
  color: #1ba098;
}

p {
  font-size: 18px;
  height: 26px;
}

.container {
  margin: 0 auto;
  text-align: center;
}

h1 {
  font-size: 2.3rem;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

.text {
  color: #1ba098;
}

.viewproject:hover {
  opacity: 0.5;
  color: #1ba098;
}

.btn {
  padding: 20px 40px;
  border: 2px solid #1ba098;
  overflow: hidden;
  position: relative;
  background-color: transparent;
  transition: all 0.5s ease;
  cursor: pointer;
  display: inline-block;
  text-transform: uppercase;
  color: #1ba098;
  text-decoration: none;
  border-radius: 25px;
}

.btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1ba098;
  transform: translateX(-100%);
  transition: transform 0.5s ease;
  z-index: -1;
}

.btn:hover {
  color: white;
}

.btn:hover::before {
  transform: translateX(0);
}

/* Ensure button does not change size */
.btn,
.btn::before {
  box-sizing: border-box; /* This ensures the padding and border are included in the element's total width and height */
}

@media only screen and (max-width: 850px) {
  #timeline ul li div {
    width: 250px;
  }

  #timeline ul li:nth-child(even) div {
    left: -284px;
  }
}

@media only screen and (max-width: 815px) {
  .about-lead {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 600px) {
  #timeline ul li {
    margin-left: 20px;
  }

  #timeline ul li div {
    width: calc(100vw - 90px);
  }

  #timeline ul li:nth-child(even) div {
    left: 15px;
  }

  #timeline ul li:nth-child(even) div:before {
    left: -15px;
    border-width: 8px 16px 8px 0;
  }

  #timeline .year {
    margin-top: 45px;
  }
}
