.project {
  position: relative;
  width: 100%;
  max-width: 1000px;
  margin: auto;
  overflow: hidden;
  margin-top: 150px;
}

.project .desc {
  margin-bottom: 50px;
}

.project h1 {
  text-align: center;
}

.image-container {
  display: flex;
  width: calc(
    100% * var(--num-images)
  ); /* Width will be 100% times the number of images */
  transition: transform 0.5s ease-in-out;
}

.image-container img {
  width: calc(
    100% / var(--num-images)
  ); /* Each image takes up a full view of the carousel */
  height: auto; /* Maintain aspect ratio */
  flex-shrink: 0; /* Prevent flexbox from shrinking the image */
  object-fit: cover; /* Cover the area without distorting aspect ratio */
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.btn {
  padding: 8px 16px;
  cursor: pointer;
  background-color: #1ba098;
  border: none;
  color: white;
  border-radius: 5px;
  font-size: 16px;
}

.btn:hover {
  background-color: #16877a;
}

.project-details {
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
}

.project-link {
  margin-top: 15px;
}

.project-technologies {
  margin-top: 20px;
  margin-bottom: 20px;
}

.skeleton {
  width: 100%;
  height: 500px;
  background-color: #eee;
  position: relative;
  overflow: hidden;
}

.skeleton:before {
  content: '';
  display: block;
  position: absolute;
  left: -150%;
  top: 0;
  height: 100%;
  width: 150%;
  background: linear-gradient(
    to right,
    transparent 0%,
    #e0e0e0 50%,
    transparent 100%
  );
  animation: load 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

@keyframes load {
  from {
    left: -150%;
  }
  to {
    left: 100%;
  }
}
