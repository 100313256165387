.about {
  background: inherit;
  padding: 2rem 0;
}

.about h1 {
  margin-bottom: 64px;
  margin-top: 150px;
}

.about-lead {
  margin-left: 30px;
  border-left: 10px dotted #1ba098;
  padding-left: 30px;
  margin-bottom: 70px;
}

#timeline ul {
  background: inherit;
  padding: 50px 0;
}

/* Create Line */
#timeline ul li {
  display: block;
  list-style: none;
  position: relative;
  width: 6px !important;
  margin: 0 auto;
  padding-top: 50px;
  background: #1ba098;
}

/* Boxes */
#timeline ul li div {
  position: relative;
  bottom: 0;
  width: 400px;
  padding: 1rem;
  background: #051622;
  transition: all 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
}

/* Right Side */
#timeline ul li:nth-child(odd) div {
  left: 10px;
  transform: translate(200px, 0);
}

/* Left Side */
#timeline ul li:nth-child(even) div {
  left: -435px;
  transform: translate(-200px, 0);
}

/* Dots */
#timeline ul li:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 25px;
  height: 25px;
  background: inherit;
  transform: translateX(-50%);
  border-radius: 50%;
  transition: background 0.5s ease-in-out;
}

/* Arrows Base */
#timeline div:before {
  content: '';
  position: absolute;
  bottom: 5px;
  width: 0;
  height: 0;
  border-style: solid;
}

/* Right Side Arrows */
#timeline ul li:nth-child(odd) div:before {
  left: -15px;
  border-width: 8px 16px 8px 0;
  border-color: transparent transparent transparent;
}

/* Left Side Arrows */
#timeline ul li:nth-child(even) div:before {
  right: -15px;
  border-width: 8px 0 8px 16px;
  border-color: transparent transparent transparent;
}

/* Show Boxes */
#timeline ul li.show div {
  transform: none;
  visibility: visible;
  opacity: 1;
}
#timeline p {
  margin-top: 60px;
}

@media only screen and (max-width: 868px) {
  #timeline p {
    margin-bottom: 30px;
    padding-top: 20px;
    padding-bottom: 70px;

  }

  .about-lead {
    margin-bottom: 130px;
  }

}
