.design-page {
  position: relative;
  width: 100%;
  max-width: 1000px; 
  margin: auto;
  overflow: hidden; 
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.design-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
}

.design-image {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 3px solid #1ba098;
  transform: scale(0.95);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.design-card h2 {
  margin: 10px 0;
  font-size: 24px;
  color: #333;
}

.design-card p {
  margin: 10px 0;
  font-size: 16px;
  color: black;
}

.design-card a {
  color: #1ba098;
}

.design-card.skeleton {
  width: 100%;
  height: 300px;
  background-color: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #ccc;
}

@keyframes load {
  0%, 100% {
    background-position: 200% 0;
  }
  50% {
    background-position: -200% 0;
  }
}
