.logo img {
  width: 150px;
  margin-top: 5px;
  margin-left: 0px;
  object-fit: cover;
}

.header-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  width: 100%;
  overflow: hidden;
}

.header-container ul li {
  display: inline;
  padding: 32px;
}

.header-container .main-nav {
  padding-top: 20px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 10%, transparent);
  color: #fff;
  opacity: 0.95;
  width: 100%;
  height: 120px;
  position: fixed;
  top: 0;
  transition: 0.5s;
  z-index: 1500;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  transition:
    top 0.3s,
    background-color 0.3s;
  z-index: 1500;
}

.navbar.hide {
  top: -150px;
}

.navbar.transparent {
  background-color: transparent;
}

.header-container .header-content {
  width: 100%;
  margin-top: 180px;
}

.header-container .header-content .img-content {
  margin-bottom: 64px;
}

.header-container .header-content .img-content img {
  width: 200px;
  height: 200px;
  border: 5px solid #1ba098;
  border-radius: 50%;
  object-fit: cover;
}

.TypeWriter {
  display: block;
  color: #ffffff;
  z-index: 1000;
  position: relative;
  align-items: center;
  width: 100%;
  height: 50px;
  margin-bottom: 80px;
}

.TypeWriter .text {
  display: block;
  width: 100%;
  font-size: 2.3rem;
}

.about.btn {
  margin-top: 60px;
  margin-bottom: 55px;
}

@media only screen and (max-width: 768px) {
  .TypeWriter .text {
    font-size: 1.6rem;
  }

  .TypeWriter {
    font-size: 1.8rem;
    margin-bottom: 30px;
  }

  .navbar .right {
    display: none;
  }

  .header-container .logo {
    display: block;
    position: fixed;
    top: 0;
    z-index: 1500;
  }

  .header-container .logo img {
    width: 100px;
   
    margin-left: 0 !important; 
  }
}
